new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.products-gallery__left').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 5,
		infinite: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 4
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 3
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}

$(document).ready(function () {

	if ($("#HomepageMarker").length === 0) {
		$(".header__logo, .top-wrapper__stars").css("display", "block");
	}
	else {
		$('.textContent h1, .testimonials__title-text').addClass('splitter anim-top');
		$('.products-intro').addClass('splitter anim-right').attr({ "data-wow-offset": "250" });
		$('.splitter').addClass('wow');

		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo, .top-wrapper__stars").addClass("wow zoomIn").css("display", "block");
		} else {
			$(".header__logo, .top-wrapper__stars").css("display", "block");
		}

		function fixPositions() {
			var bgHeight = $('.products-intro').offset().top - $('.columns__wrapper').offset().top;
			$('.columns__wrapper-bg').css({ height: bgHeight });
			$('.columns__wrapper-bg-out').css({ top: bgHeight - 2 });
			$('.testimonials__link').sameHeight();
		}

		setTimeout(fixPositions, 300);

		$(window).on("resize", function () {
			setTimeout(fixPositions, 300);
		});
	}

	var splitter = document.querySelectorAll('.splitter');

	Splitting({
		target: splitter,
	});

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

});
